<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354.43 60.1">
    <defs>
      <linearGradient id="gradient">
        <stop offset="5%" stop-color="#6d28d9" />
        <stop offset="95%" stop-color="#3b82f6" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M37.22,31.42c-3.42-2-8-3.4-14.5-4.49l-.39-.07C11.19,24.94,6.22,21.94,6.22,17.14c0-5.22,7.32-9.63,16-9.63a22.14,22.14,0,0,1,16.25,7l.51.54,4.55-4.23L43,10.3a28.7,28.7,0,0,0-20.8-9A27.69,27.69,0,0,0,7,5.55c-4.5,3-7,7.11-7,11.59C0,22,2.42,25.81,7.19,28.57c3.42,2,8,3.4,14.5,4.49l.39.07c11.15,1.92,16.12,4.92,16.12,9.72,0,5.22-7.33,9.62-16,9.62A22.14,22.14,0,0,1,6,45.45l-.51-.54L.89,49.14l.52.55a28.66,28.66,0,0,0,20.8,9,27.74,27.74,0,0,0,15.23-4.25c4.49-3,7-7.12,7-11.59C44.41,38,42,34.18,37.22,31.42Z"
        />
        <rect
          x="249.88"
          y="26.93"
          width="63.56"
          height="6.25"
          transform="translate(128.81 268.54) rotate(-63.43)"
        />
        <polygon
          points="270.07 1.3 212.32 1.3 212.32 7.54 238.18 7.54 238.18 58.81 244.43 58.81 244.43 7.54 270.07 7.54 270.07 1.3"
        />
        <path
          d="M350,43.76,347.81,46a22.55,22.55,0,1,1,0-31.86L350,16.32l4.41-4.43-2.22-2.2a28.32,28.32,0,0,0-5.92-4.54,28.76,28.76,0,1,0,0,49.81,29.32,29.32,0,0,0,6-4.57l2.2-2.21Z"
        />
        <path
          d="M192,1.3H163.2V7.54H192a9.7,9.7,0,0,1,0,19.39H163.2V58.81h6.24V33.18H192A15.94,15.94,0,0,0,192,1.3Z"
        />
        <rect x="55.4" y="1.3" width="6.25" height="57.51" />
        <polygon
          points="99.35 4.76 95.31 0 62.04 30.05 95.31 60.1 99.35 55.35 71.6 30.05 99.35 4.76"
        />
        <rect x="111.07" y="1.3" width="35.88" height="6.25" />
        <rect x="111.07" y="26.93" width="35.88" height="6.25" />
        <rect x="111.07" y="52.56" width="35.88" height="6.25" />
      </g>
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  fill: $primary;
}
</style>
