import { LogoutOptions, useAuth0 } from "@auth0/auth0-vue";
import { computed, watch } from "vue";
import { useNotification } from "@/composeables/useNotification";
import { useFeatures } from "@/store/features";

export function useAuth() {
  const auth0 = useAuth0();

  // @ts-ignore
  const audience = auth0.clientOptions.authorizationParams.audience;

  const user = computed(() => auth0.user.value);
  const userAudience = computed(() => user.value && user.value[audience]);

  const roles = computed(() => {
    if (!userAudience.value) {
      return [];
    }
    return userAudience.value.roles;
  });

  return {
    roles,
    user,
    isAuthenticated: computed(() => auth0.isAuthenticated.value),
    isLoading: computed(() => auth0.isLoading.value),
    getToken() {
      return auth0.getAccessTokenSilently();
    },
    logout(args?: LogoutOptions) {
      auth0.logout(args);
    },
  };
}

export const permissionGuard = async (to: any, from: any, next: any) => {
  const auth = useAuth();
  const notification = useNotification();

  const features = useFeatures();
  await features.fetch();

  const fn = () => {
    if (
      to.matched.every(
        ({ meta }: { meta: any }) =>
          meta.requiresPermission === undefined ||
          features.can(meta.requiresPermission),
      )
    ) {
      return next();
    }

    // If this is not the first page load, and the user is trying to land on a page they
    // don't have access to, then show a warning.
    if (from.matched.length > 0) {
      notification.danger("You do not have permission to view this page");
    }

    next({ name: "home" });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!auth.isLoading.value) {
    return fn();
  }

  // Watch for the loading property to change before we check permissions
  watch(auth.isLoading, (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
