<template>
  <div>
    <div>
      <div class="tabs is-centered">
        <ul>
          <router-link
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'creatives', path: 'creatives' }"
          >
            <li :class="isActive && 'is-active'">
              <a :href="href" @click="navigate">Creatives</a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
    <div class="columns">
      <router-view class="column"></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
