<template>
  <o-tooltip
    :multiline="multiline"
    :label="label"
    :position="pos"
    :delay="delay"
  >
    <slot></slot>
  </o-tooltip>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

const classMapping: Record<string, string> = {
  bottom: "bottom",
  top: "top",
  left: "left",
  right: "right",
};

export default defineComponent({
  props: {
    multiline: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    position: {
      type: String,
      required: false,
      default: "top",
    },
    delay: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props) {
    return {
      pos: computed(() => {
        return classMapping[props.position] || "top";
      }),
    };
  },
});
</script>
