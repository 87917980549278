import { output, ZodTypeAny } from "zod";
import * as Sentry from "@sentry/browser";
export * from "zod";

export function safeParse<T extends ZodTypeAny>(
  schema: T,
  data: unknown,
): output<T> {
  const result = schema.safeParse(data);

  if (!result.success) {
    Sentry.addBreadcrumb({
      category: "data-boundary-exception",
      data: {
        error: result.error,
        data,
      },
      // @ts-ignore
      level: "error",
    });

    // eslint-disable-next-line no-console
    console.trace("Data boundary exception", {
      error: result.error,
      data,
    });

    return data as output<T>;
  }

  return result.data;
}
