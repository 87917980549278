import FeaturesService, { Features } from "@/services/features";
import { acceptHMRUpdate, defineStore } from "pinia";

export const useFeatures = defineStore("features", {
  state: () => ({
    features: {} as Features,
    permissions: [] as string[],
    initialised: false,
  }),
  getters: {
    featureEnabled: (state) => {
      return (feature: string) => !!state.features[feature];
    },
    can: (state) => {
      return (permission: string) => state.permissions.includes(permission);
    },
  },
  actions: {
    async fetch() {
      if (this.initialised) {
        return;
      }

      const featuresList = await FeaturesService.fetchFeatures();
      this.features = featuresList.features;
      this.permissions = featuresList.permissions;
      this.initialised = true;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFeatures, import.meta.hot));
}
