let ENVIRONMENT: "development" | "production" | "local";

switch (window.location.host) {
  case "eks.skeptic.known.is":
  case "skeptic.known.is":
    ENVIRONMENT = "production";
    break;
  case "dev.skeptic.known.is":
    ENVIRONMENT = "development";
    break;
  default:
    ENVIRONMENT = "local";
}

const configByEnv = {
  local: {
    ENVIRONMENT,
    VERSION: import.meta.env.VITE_GIT_HASH,
    SENTRY_DSN: undefined,
    GA_TRACKING_ID: undefined,
    AUTH0_AUDIENCE:
      import.meta.env.VITE_AUTH0_AUDIENCE ??
      "https://media-science.dev.known.is/api/",
    AUTH0_DOMAIN:
      import.meta.env.VITE_AUTH0_DOMAIN ?? "media-science.auth0.com",
    AUTH0_CLIENT_ID:
      import.meta.env.VITE_AUTH0_CLIENT_ID ??
      "mCdRAXOlcwU7BYJBL3MZqP7NfIDayvHQ",
    PUSHER_HOST: import.meta.env.VITE_PUSHER_HOST || window.location.hostname,
    PUSHER_PORT: import.meta.env.VITE_PUSHER_PORT || window.location.port,
    PUSHER_TLS: false,
    PUSHER_KEY: "pusher",
  },
  development: {
    ENVIRONMENT,
    VERSION: import.meta.env.VITE_GIT_HASH,
    SENTRY_DSN:
      "https://4d08693c43c34cfa8efab54aeb7ecbe1@o59586.ingest.sentry.io/1855321",
    GA_TRACKING_ID: undefined,
    AUTH0_AUDIENCE:
      import.meta.env.VITE_AUTH0_AUDIENCE ??
      "https://media-science.dev.known.is/api/",
    AUTH0_DOMAIN:
      import.meta.env.VITE_AUTH0_DOMAIN ?? "media-science.auth0.com",
    AUTH0_CLIENT_ID:
      import.meta.env.VITE_AUTH0_CLIENT_ID ??
      "mCdRAXOlcwU7BYJBL3MZqP7NfIDayvHQ",
    PUSHER_HOST: window.location.hostname,
    PUSHER_PORT: 443,
    PUSHER_TLS: true,
    PUSHER_KEY: "skeptic-dev",
  },
  production: {
    ENVIRONMENT,
    VERSION: import.meta.env.VITE_GIT_HASH,
    SENTRY_DSN:
      "https://4d08693c43c34cfa8efab54aeb7ecbe1@o59586.ingest.sentry.io/1855321",
    GA_TRACKING_ID: "G-FYVE5G3T00",
    AUTH0_AUDIENCE: "https://media-science.known.is/api/",
    AUTH0_DOMAIN: "media-science.auth0.com",
    AUTH0_CLIENT_ID: "Se0BVxGaDBzHCSE8DaviSAf0MjQZd8Rl",
    PUSHER_HOST: window.location.hostname,
    PUSHER_PORT: 443,
    PUSHER_TLS: true,
    PUSHER_KEY: "skeptic-prod",
  },
};

export default configByEnv[ENVIRONMENT];
