import { library } from "@fortawesome/fontawesome-svg-core";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import {
  faCopy,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBars,
  faBook,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronLeft,
  faCircleQuestion,
  faCircleXmark,
  faClockRotateLeft,
  faCog,
  faCommentDots,
  faDollarSign,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFile,
  faFileDownload,
  faFileUpload,
  faFileExport,
  faFlask,
  faFolder,
  faGamepad,
  faHeadset,
  faInfoCircle,
  faLock,
  faPercent,
  faPlus,
  faPlusSquare,
  faSave,
  faSearch,
  faSync,
  faTimes,
  faTrash,
  faUndo,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAmazon,
  faApple,
  faFacebookF,
  faGoogle,
  faLinkedin,
  faPinterest,
  faReddit,
  faSnapchat,
  faTiktok,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faCalendar,
  faSync,
  faGamepad,
  faBookmark,
  faPlus,
  faTimes,
  faCircleQuestion,
  faCircleXmark,
  faCaretUp,
  faCaretDown,
  faCaretRight,
  faChevronLeft,
  faClockRotateLeft,
  faCommentDots,
  faCog,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faHeadset,
  faInfoCircle,
  faLock,
  faExclamationTriangle,
  faEllipsisV,
  faFlask,
  faPlusSquare,
  faDollarSign,
  faTrash,
  faPercent,
  faFileUpload,
  faFileExport,
  faFileDownload,
  faSave,
  faBars,
  faBook,
  faEdit,
  faCheck,
  faExternalLinkAlt,
  faUndo,
  faSearch,
  faFacebookF,
  faTiktok,
  faGoogle,
  faApple,
  faLinkedin,
  faUserCircle,
  faArrowUp,
  faArrowDown,
  faTwitter,
  faPinterest,
  faSnapchat,
  faFolder,
  faFile,
  faAmazon,
  faReddit,
  faArrowLeft,
  faCopy,
);

export {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
