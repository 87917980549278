<template>
  <div class="section">
    <div class="columns is-multiline is-centered is-size-4">
      <component
        :is="tile.routeName ? 'RouterLink' : 'a'"
        v-for="tile in tiles"
        :key="tile.title"
        :to="{ name: tile.routeName }"
        :href="tile.href"
        class="column is-one-third"
      >
        <p
          class="notification is-skeptic-purple is-flex is-justify-content-center is-align-items-center has-text-weight-bold"
        >
          {{ tile.title }}
        </p>
      </component>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const tiles = [
      { routeName: "ad-creator", title: "Ad Creator" },
      { routeName: "ad-ops-automation", title: "Ad-Ops" },
      { routeName: "advertiser", title: "Advertiser Account Setup" },
      {
        routeName: "cross-channel-budget-allocation",
        title: "Cross-Channel Budget Allocation",
      },
      { routeName: "landing-page-qa", title: "Landing Page QA" },
      // NOTE: MMM currently uses the same domain as skeptic app, and the routing between the
      // is currently controlled by Amabassador.
      { href: "/mmm-ui", title: "MMM" },
      {
        routeName: "post-log-aggregation",
        title: "Network Post-Log Aggregation",
      },
      { routeName: "ooh-automation", title: "OOH Posting Instructions" },
      { routeName: "platform-qa", title: "Platform QA" },
      { routeName: "audience-import", title: "Audience Import" },
    ];

    return {
      tiles: tiles.sort((a, b) => a.title.localeCompare(b.title)),
    };
  },
});
</script>

<style scoped land="scss">
.notification {
  height: 12rem;
}

.is-skeptic-purple {
  background-color: #e2dff1;
  color: #6c28d9;

  &:hover {
    background-color: #6c28d9;
    color: #e2dff1;
  }
}
</style>
