import axios, { AxiosError } from "axios";
import auth0 from "@/auth0";
import { z } from "zod";
import { join } from "lodash";

import httpProgress from "./services/http-progress";

const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || typeof window === "undefined"
    ? String(import.meta.env.VITE_API_BASE_URL)
    : `/api/`;

const http = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5 * 60 * 1000,
  responseType: "json",
  withProgress: true,
});

httpProgress(http);

const UnprocessableEntityResponse = z.record(z.array(z.string()));

export class ErrorMap extends Map<string, string[]> {
  getString(key: string): string {
    return join(super.get(key));
  }
}

export class ValidationError {
  errors: ErrorMap = new ErrorMap();

  constructor(err: AxiosError) {
    const result = UnprocessableEntityResponse.safeParse(err.response?.data);
    if (result.success) {
      this.errors = new ErrorMap(Object.entries(result.data));
    }

    // Backwards compatibility with code that doesn't know about the ValidationError class
    Object.assign(this, err);
  }
}

export class NotFoundError {}

http.interceptors.request.use(
  async (config) => {
    const token = await auth0.getAccessTokenSilently();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

http.interceptors.response.use(undefined, (err) => {
  if (axios.isAxiosError(err) && err.response?.status === 422) {
    return Promise.reject(new ValidationError(err));
  }
  if (axios.isAxiosError(err) && err.response?.status === 404) {
    return Promise.reject(new NotFoundError());
  }
  return Promise.reject(err);
});

export default http;
