import NProgress from "nprogress";
import axios, { AxiosInstance } from "axios";
import { delay } from "lodash";

export default function (http: AxiosInstance) {
  let requestCount = 0;

  function start() {
    NProgress.start();
    requestCount++;
  }

  function end() {
    requestCount--;
    delay(() => {
      if (0 === requestCount) {
        NProgress.done(true);
      }
    }, 100);
  }

  http.interceptors.request.use(
    (config) => {
      if (config.withProgress) {
        start();
      }
      return config;
    },
    (err) => {
      if (axios.isAxiosError(err) && err.config?.withProgress) {
        end();
      }

      return Promise.reject(err);
    },
  );

  http.interceptors.response.use(
    (response) => {
      if (response.config.withProgress) {
        end();
      }
      return response;
    },
    (err) => {
      if (axios.isAxiosError(err) && err.config?.withProgress) {
        end();
      }

      return Promise.reject(err);
    },
  );
}
