import { SentryPlugin } from "@/plugins/sentry";
import { FontAwesomeIcon, FontAwesomeLayers } from "@/font-awesome";
import { createApp } from "vue";
import VueGtag from "vue-gtag";
import { createPinia } from "pinia";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";
import Multiselect from "@vueform/multiselect";

import "@/scss/custom.scss";
import auth0 from "@/auth0";
import config from "@/services/config";
import router from "@/router";
import App from "@/App.vue";
import { initializePusher } from "@/pusher";

initializePusher();

createApp(App)
  .use(router)
  .use(auth0)
  .use(new SentryPlugin())
  .use(createPinia())
  .use(Oruga, {
    ...bulmaConfig,
    iconComponent: "font-awesome-icon",
    iconPack: "fa",
  })
  .use(
    VueGtag,
    {
      config: { id: config.GA_TRACKING_ID || "" },
    },
    router,
  )
  .component("FontAwesomeIcon", FontAwesomeIcon)
  .component("FontAwesomeLayers", FontAwesomeLayers)
  .component("Multiselect", Multiselect)
  .mount("#app");
