import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import config from "@/services/config";
import { App } from "vue";
import router from "@/router";
import axios from "axios";

const { ENVIRONMENT, SENTRY_DSN } = config;

export class SentryPlugin {
  install(app: App) {
    if (SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: SENTRY_DSN,
        environment: ENVIRONMENT,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Integrations.InboundFilters({
            ignoreErrors: [
              /^Multifactor authentication required$/,
              /^Missing Refresh Token.*$/,
              /^.*Login Required.*$/,
            ],
          }),
        ],
        tracesSampleRate: 1.0,
        release: import.meta.env.VITE_GIT_HASH,
        beforeSend: (event, hint) => {
          const err = hint?.originalException;

          if (axios.isAxiosError(err) && err.response?.status === 500) {
            return null;
          }

          return event;
        },
      });
    }
  }
}
