<template>
  <nav
    class="navbar px-6 has-shadow"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item pl-0" :to="{ name: 'home' }">
        <HeaderLogo style="width: 150px"></HeaderLogo> </router-link
      ><a
        class="navbar-burger"
        :class="{ 'is-active': isActive }"
        @click="isActive = !isActive"
        ><span></span><span></span><span></span
      ></a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': isActive }">
      <div class="navbar-end"></div>
      <div class="navbar-item">
        <VTooltip
          position="bottom"
          label="Run applications from our Data Science team"
        >
          <a class="button is-primary is-light" href="/labs/">
            <FontAwesomeIcon icon="flask"></FontAwesomeIcon> &nbsp; Skeptic
            Labs</a
          >
        </VTooltip>
      </div>
      <div class="navbar-item">
        <VTooltip position="bottom" label="View the user documentation">
          <a
            class="button is-primary is-light"
            href="https://drive.google.com/drive/folders/1Oxi36P2mh-sS0FDL9YR2FFTFIJCddv4Q"
          >
            <FontAwesomeIcon icon="book"></FontAwesomeIcon> &nbsp; User Docs</a
          >
        </VTooltip>
      </div>
      <div class="navbar-item">
        <VTooltip position="bottom" label="View our wiki">
          <a
            class="button is-primary is-light"
            href="https://sites.google.com/known.is/the-knownos/skeptic"
          >
            <FontAwesomeIcon icon="circle-question"></FontAwesomeIcon> &nbsp;
            Wiki</a
          >
        </VTooltip>
      </div>
      <div class="navbar-item">
        <VTooltip position="bottom" label="Report a bug or request a feature">
          <a
            class="button is-primary is-light"
            href="https://known.atlassian.net/servicedesk/customer/portal/25"
          >
            <FontAwesomeIcon icon="headset"></FontAwesomeIcon> &nbsp; Support
            Desk</a
          >
        </VTooltip>
        <VDropdown :label="userEmail"
          ><template #label="{ label }"
            ><div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link">
                <div class="icon mr-2">
                  <font-awesome-icon icon="user-circle"></font-awesome-icon>
                </div>
                <span>{{ label }}</span>
              </div>
            </div></template
          >
          <div>
            <router-link
              v-if="showHealthStatus"
              class="navbar-item has-text-secondary"
              to="/health"
              active-class="has-text-primary"
              >Health Status</router-link
            >
          </div>
          <a
            class="navbar-item has-text-secondary"
            href="https://sites.google.com/known.is/the-knownos/skeptic"
            >Skeptic Wiki</a
          >
          <hr class="dropdown-divider" />
          <a class="navbar-item has-text-secondary" @click="logout"
            >Log out</a
          ></VDropdown
        >
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { useFeatures } from "@/store/features";
import { filter } from "lodash";
import { defineComponent, computed, ref } from "vue";
import { useAuth } from "@/composeables/useAuth";

interface NavbarItem {
  label: string;
  route: { name: string };
  show?: () => boolean;
  childGroups?: ChildGroup[];
  children?: NavbarItem[];
}

interface ChildGroup {
  name: string;
  children: NavbarItem[];
}

export default defineComponent({
  setup() {
    const isActive = ref(false);

    const auth = useAuth();
    const features = useFeatures();

    return {
      isActive,

      userEmail: computed(() => auth.user.value?.name),
      showHealthStatus: computed(() => features.can("view:app-status")),

      hasLink(item: NavbarItem) {
        return !item.children && !item.childGroups;
      },
      childGroups(item: NavbarItem): ChildGroup[] {
        if (!item.childGroups) {
          return [];
        }
        return item.childGroups;
      },

      availableChildren(item: NavbarItem | ChildGroup): NavbarItem[] {
        return filter(item.children, ({ show }) => !show || show());
      },
      logout() {
        auth.logout({
          logoutParams: { returnTo: window.location.origin },
        });
      },
    };
  },
});
</script>
<style lang="scss" scoped>
.navbar-item {
  padding: 0 0.15em;
}
</style>
