<template>
  <o-dropdown aria-role="list">
    <template #trigger>
      <slot name="label" :label="label"
        ><span>{{ label }}</span></slot
      >
    </template>
    <template v-for="(_, slot) of slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope"></slot>
    </template>
  </o-dropdown>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props, { slots }) {
    return {
      slots,
    };
  },
});
</script>
