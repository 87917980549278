import http from "@/http";
import { safeParse, z } from "@/services/zod";

const Features = z.record(z.boolean());
export type Features = z.infer<typeof Features>;

const FeatureList = z.object({
  permissions: z.array(z.string()),
  features: Features,
});

export type FeatureList = z.infer<typeof FeatureList>;

export default {
  async fetchFeatures(): Promise<FeatureList> {
    const { data } = await http.post("/feature.list");
    return safeParse(FeatureList, data);
  },
};
