import { NotificationProgrammatic } from "@oruga-ui/oruga-next";
import { includes } from "lodash";

interface NotificationInput {
  autoClose: boolean;
  closable: boolean;
  pauseOnHover: boolean;
  hasIcon: boolean;
  queue: boolean;
  duration: number;
  position:
    | "top-right"
    | "top"
    | "top-left"
    | "bottom-right"
    | "bottom"
    | "bottom-left";
  variant: "info" | "success" | "warning" | "danger";
  iconPack: "fa";
}

const defaults: Partial<NotificationInput> = {
  closable: true,
  queue: false,
  hasIcon: true,
  pauseOnHover: true,
  duration: 5000,
  position: "top-right",
  variant: "info",
};

const staticOptions = {
  iconPack: "fa",
  iconSize: "small",
};

const iconMap = {
  info: "info-circle",
  success: "check",
  warning: "exclamation-triangle",
  danger: "exclamation-triangle",
};

export function useNotification(staticInput: Partial<NotificationInput> = {}) {
  function open(message: string, input: Partial<NotificationInput> = {}) {
    const partialPayload: Partial<NotificationInput> = {
      ...defaults,
      ...staticInput,
      ...input,
    };

    const autoClose =
      partialPayload.autoClose ||
      includes(["info", "success"], partialPayload.variant);

    const payload = {
      icon: iconMap[partialPayload.variant || "info"],
      autoClose,
      indefinite: !autoClose,
      message,
      ...partialPayload,
      ...staticOptions,
    };

    NotificationProgrammatic.open(payload);
  }

  function info(
    message: string,
    input: Partial<Omit<NotificationInput, "variant">> = {},
  ) {
    open(message, { ...input, variant: "info" });
  }

  function success(
    message: string,
    input: Partial<Omit<NotificationInput, "variant">> = {},
  ) {
    open(message, { ...input, variant: "success" });
  }

  function warning(
    message: string,
    input: Partial<Omit<NotificationInput, "variant">> = {},
  ) {
    open(message, { ...input, variant: "warning" });
  }

  function danger(
    message: string,
    input: Partial<Omit<NotificationInput, "variant">> = {},
  ) {
    open(message, { ...input, variant: "danger" });
  }

  function exception(
    message: string,
    e: any,
    input: Partial<Omit<NotificationInput, "variant">> = {},
  ) {
    const errMessage = e.message;
    const responseData = e.response?.data?.error;

    message = `${message}: ${errMessage}`;
    if (responseData) {
      message = `${message}<br /><br />${responseData}`;
    }

    danger(message, input);
  }

  return { open, info, warning, danger, success, exception };
}
